<template>
  <img :src="Logo"/>
</template>

<script>
  export default{
    data() {
      return {
        Logo: require('@/assets/images/logo/Subsidios.svg'),
      }
    },
  }
</script>

<style lang="scss">
  img {
    width: 70%;
  }
</style>
