<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          404 - Página no encontrada!
        </h2>
        <p class="mb-2">
          Lo sentimos, pero la página que busca no existe o no se puede encontrar.
        </p>
        <b-button
          v-if="role.name !== 'PreapprovalUser'"
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{name:'home'}">
          Volver al inicio
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{name:'preapprovals'}">
          Volver al inicio
        </b-button>
        <b-img fluid
          :src="imgUrl"
          alt="Error page"/>
      </div>
    </div>
  </div>

</template>

<script>
  import {mapState} from 'vuex'
  import { BLink, BButton, BImg } from 'bootstrap-vue'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import store from '@/store/index'

  export default {
    components: {
      VuexyLogo,
      BLink,
      BButton,
      BImg,
    },
    data() {
      return {
        downImg: require('@/assets/images/svg/error.svg'),
      }
    },
    computed: {
      ...mapState('profile',['role']),
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          this.downImg = require('@/assets/images/pages/error-dark.svg')
          return this.downImg
        }
        return this.downImg
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss'
</style>
